import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { BCMSkillPage } from '../../../modules/bcm/common/components/bcm-skillpage';

const BCMGuidesGearSets: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="bcm">
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/black-clover/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Great lower rarity Skill Pages</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/blackclover/categories/cat_skillpages.png"
            alt="Great Skill Pages"
          />
        </div>
        <div className="page-details">
          <h1>Great lower rarity Skill Pages</h1>
          <h2>Great lower rarity Skill Pages in Black Clover Mobile.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          As a rule of thumb, if you have the Signature Skill Page for that
          character, just equip it. The upgraded version of the skill you that
          way it's the biggest boost the character can get and even the Skill
          Page passive often works well with the kit.
        </p>
        <p>
          If you don't have te Signature Skill Page, you are forced to either
          use the Universal ones or the Class specific ones - if you equip a
          Skill Page from other class, the passive won't activate.
        </p>
        <SectionHeader title="Attacker Skill Pages" />
        <p>
          Here are the best Attacker Skill Pages you can use in Black Clover
          Mobile:
        </p>
        <div className="employee-container for-nikke skill-pages">
          <BCMSkillPage slug="sea-serpents-pride" mode="icon" />

          <BCMSkillPage slug="sharp-eye" mode="icon" />
        </div>
        <ul>
          <li>
            <strong>Sea Serpent's Pride</strong> gives 7/15% CRIT Rate and
            that's more than some of the Gear Sets you can farm - if you don't
            have the signature Skill Page, make sure to use this one on your
            Attackers,
          </li>
          <li>
            <strong>Sharp Eye</strong> gives a nice 5/10% ATK and MATK boost and
            since its rarity is R, it's easy to superimpose it.
          </li>
        </ul>
        <SectionHeader title="Debuffer Skill Pages" />
        <p>
          Here are the best Debuffer Skill Pages you can use in Black Clover
          Mobile:
        </p>
        <div className="employee-container for-nikke skill-pages">
          <BCMSkillPage slug="seniors-dignity" mode="icon" />
        </div>
        <ul>
          <li>
            <strong>Senior's Dignity</strong> gives 10% ACCURACY and 10/20% PEN
            - the first stat will help your debuffers land their debuffs, and
            the second will help in increasing their damage output.
          </li>
        </ul>
        <SectionHeader title="Defender Skill Pages" />
        <p>
          Here are the best Defender Skill Pages you can use in Black Clover
          Mobile:
        </p>
        <div className="employee-container for-nikke skill-pages">
          <BCMSkillPage slug="false-confidence" mode="icon" />

          <BCMSkillPage slug="loyal-sister" mode="icon" />

          <BCMSkillPage slug="persevering-resolution" mode="icon" />
        </div>
        <ul>
          <li>
            <strong>False Confidence</strong> gives both 5% Max HP and 10/30%
            DEF - both of those stats will help your Defenders to stay alive
            longer,
          </li>
          <li>
            <strong>Loyal Sister</strong> is a trigger based Skill Page that has
            a 20% chance to reduce the ATK of the enemy who attacked your
            Defender,
          </li>
          <li>
            <strong>Persevering Resolution</strong> is only worth using against
            Bosses, but it gives a massive damage reduction then (10/18%).
          </li>
        </ul>
        <SectionHeader title="Healer Skill Pages" />
        <p>
          Here are the best Healer Skill Pages you can use in Black Clover
          Mobile:
        </p>
        <div className="employee-container for-nikke skill-pages">
          <BCMSkillPage slug="crimson-she-leopard" mode="icon" />

          <BCMSkillPage slug="meat-enthusiast" mode="icon" />

          <BCMSkillPage slug="gentle-compassion" mode="icon" />
        </div>
        <SectionHeader title="Supporter Skill Pages" />
        <p>
          Here are the best Supporter Skill Pages you can use in Black Clover
          Mobile:
        </p>
        <div className="employee-container for-nikke skill-pages">
          <BCMSkillPage slug="cultists-retribution" mode="icon" />

          <BCMSkillPage slug="excited-nature" mode="icon" />

          <BCMSkillPage slug="oath-of-protection" mode="icon" />
        </div>
        <SectionHeader title="Universal Skill Pages" />
        <p>
          Here are the best universal Skill Pages you can use in Black Clover
          Mobile:
        </p>
        <div className="employee-container for-nikke skill-pages">
          <BCMSkillPage slug="adventurers-journal" mode="icon" />

          <BCMSkillPage slug="breath-of-darkness" mode="icon" />

          <BCMSkillPage slug="breath-of-flame" mode="icon" />

          <BCMSkillPage slug="moonlight-necklace" mode="icon" />
          <BCMSkillPage slug="mysterious-ancient-book" mode="icon" />
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default BCMGuidesGearSets;

export const Head: React.FC = () => (
  <Seo
    title="Great lower rarity Skill Pages | Black Clover M | Prydwen Institute"
    description="Great lower rarity Skill Pages in Black Clover Mobile."
  />
);
