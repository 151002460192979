import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IBCMSkillPage } from '../../../common/model/graphql-types';

interface IProps {
  employee: IBCMSkillPage;
  showLabel?: boolean;
  showTags?: boolean;
  showIcon?: boolean;
}

export const BCMSkillPageIcon: React.FC<IProps> = ({
  employee,
  showLabel,
  showTags,
  showIcon
}) => {
  const image = employee.image
    ? getImage(employee.image.localFile.childImageSharp)
    : null;
  return (
    <Link to={'/black-clover/skill-page/' + employee.slug}>
      <div
        className={`avatar bcm ${employee.rarity} ${
          showLabel ? showLabel : ''
        }`}
      >
        {image && (
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${employee.name}`}
          />
        )}
      </div>
      {showIcon && employee.isSeasonal && (
        <span className="floating-seasonal">S{employee.season}</span>
      )}
      {showLabel && <span className="emp-name">{employee.name}</span>}
      {showLabel && employee.isNew && showTags && (
        <span className="tag new">New</span>
      )}
    </Link>
  );
};
